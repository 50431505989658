

import React, { Children,FC } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import Logo from '../../Assets/LogoClr.svg'
import './sidebarStudentProfile.css'
interface SidebarStudentProfileProps {
  currentSidebarPage: string
  innerPageCount:number
  profileData:any

}
const SidebarStudentProfile: FC<SidebarStudentProfileProps> = ({ currentSidebarPage,innerPageCount,profileData}) => {
  
  const profileStrength:number=React.useMemo(()=>{

    const totalInputs = 31
    const filledValuesAccount = 
    (profileData?.account?.citizenCountry===null || profileData?.account?.citizenCountry?.trim()?.length===0?0:1)
    + (profileData?.account?.country===null|| profileData?.account?.country?.trim()?.length===0?0:1)
    + (profileData?.account?.firstName===null|| profileData?.account?.firstName?.trim()?.length===0?0:1)
    + (profileData?.account?.lastName===null|| profileData?.account?.lastName?.trim()?.length===0?0:1)
    + (profileData?.account?.phone===null|| profileData?.account?.phone?.trim()?.length===0?0:1)
    + (profileData?.account?.profilePic===null|| profileData?.account?.profilePic?.trim()?.length===0?0:1)
    + (profileData?.account?.region===null|| profileData?.account?.region?.trim()?.length===0?0:1)
    + (profileData?.account?.workAuth===null|| profileData?.account?.workAuth?.trim()?.length===0?0:1)

    const filledValuesCarrier_stack = 
    (profileData?.carrier_stack?.carrierType===null || profileData?.carrier_stack?.carrierType?.trim()?.length===0?0:1) 
    +  ( profileData?.carrier_stack?.linkedinProfile===null || profileData?.carrier_stack?.linkedinProfile?.trim()?.length===0?0:1) 
    +     (profileData?.carrier_stack?.resume===null || profileData?.carrier_stack?.resume?.trim()?.length===0?0:1) 

    const filledValuesWork_Pref = 
    (profileData?.workPrefrence?.coverLetter===null || profileData?.workPrefrence?.coverLetter?.trim()?.length===0?0:1)
    +  (profileData?.workPrefrence?.prefWorkLocation===null || profileData?.workPrefrence?.prefWorkLocation?.trim()?.length===0?0:1)
    +  (profileData?.workPrefrence?.NoticePeriod===null || profileData?.NoticePeriod?.prefWorkLocation?.trim()?.length===0?0:1)
    +  (profileData?.workPrefrence?.desiredSal===null || profileData?.workPrefrence?.desiredSal?.trim()?.length===0?0:1)
    +  (profileData?.workPrefrence?.carGoals===null || profileData?.workPrefrence?.carGoals?.trim()?.length===0?0:1)
    +  (profileData?.workPrefrence?.prefJobType===null || profileData?.workPrefrence?.prefJobType?.trim()?.length===0?0:1)


    const filledValuesWork_Education = 
    (profileData?.education?.[0]?.areaStudy===null || profileData?.education?.[0]?.areaStudy?.trim()?.length===0?0:1)
    + (profileData?.education?.[0]?.degree===null || profileData?.education?.[0]?.degree?.trim()?.length===0?0:1)
    + (profileData?.education?.[0]?.eduName===null || profileData?.education?.[0]?.eduName?.trim()?.length===0?0:1)
    + (profileData?.education?.[0]?.school===null || profileData?.education?.[0]?.school?.trim()?.length===0?0:1)
    + (profileData?.education?.[0]?.specialization===null || profileData?.education?.[0]?.specialization?.trim()?.length===0?0:1)

    const filledValuesWork_Experience = 
    (profileData?.workExperience?.[0]?.company===null || profileData?.workExperience?.[0]?.company?.trim()?.length===0?0:1) 
    + (profileData?.workExperience?.[0]?.currentlyWork===null || profileData?.workExperience?.[0]?.currentlyWork?.trim()?.length===0?0:1) 
    + (profileData?.workExperience?.[0]?.description===null || profileData?.workExperience?.[0]?.description?.trim()?.length===0?0:1) 
    + (profileData?.workExperience?.[0]?.expName?.[0]===null || profileData?.workExperience?.[0]?.expName?.[0]?.trim()?.length===0?0:1) 
    + (profileData?.workExperience?.[0]?.industry===null || profileData?.workExperience?.[0]?.industry?.trim()?.length===0?0:1) 
    + (profileData?.workExperience?.[0]?.jobType===null || profileData?.workExperience?.[0]?.jobType?.trim()?.length===0?0:1) 
    + (profileData?.workExperience?.[0]?.location===null || profileData?.workExperience?.[0]?.location?.trim()?.length===0?0:1) 
    + (profileData?.workExperience?.[0]?.title===null || profileData?.workExperience?.[0]?.title?.trim()?.length===0?0:1)
    
    const totalInputFilled = filledValuesAccount 
                    + filledValuesCarrier_stack 
                    + filledValuesWork_Education 
                    + filledValuesWork_Experience 
                    + filledValuesWork_Pref + (profileData?.skills?.length>=2?1:0)

      const strength = (totalInputFilled/totalInputs)*100   
      return Math.floor(strength)      
  },[profileData,profileData?.carrier_stack?.carrierType])
  const renderTabs = React.useCallback((count:number,type:string)=>{
    
    return <div className='tabsWrapper'><div className='lineStudentProfile'  style={{backgroundColor:currentSidebarPage===type?'#0250C5':'#D8DBE1'}}></div>
    {count===2 &&<div  style={{backgroundColor:currentSidebarPage===type && innerPageCount===2?'#0250C5':'#D8DBE1'}} 
    className='lineStudentProfile'></div>}</div>
  },[currentSidebarPage,innerPageCount])
  return (
    <div className="sidebarWrapperStudentProfile">
        <img className='sidebarWrapperStudentProfileLogo' src={Logo} alt="logo" />
  <div className='sidebarContentProfile'>
       <div className='categoryWrapper' ><span className='categoryText' >Career Track</span> {renderTabs(2,'Career')} </div>
       <div className='categoryWrapper' ><span className='categoryText'>Account</span>{renderTabs(2,'Account')}</div>
       <div className='categoryWrapper'><span className='categoryText'>Education</span>{renderTabs(1,'Education')}</div>
       <div className='categoryWrapper' ><span className='categoryText'>Experiences       </span>{renderTabs(1,'Experiences')}</div>
       <div className='categoryWrapper'><span className='categoryText'>Skills</span>{renderTabs(1,'Skills')}</div>
       <div className='categoryWrapper'><span className='categoryText'>Preferences</span>{renderTabs(2,'Preferences')}</div>
       <div className='categoryWrapper'><span className='categoryText'>Others</span>{renderTabs(1,'Others')}</div>
       <div className='profileStrengthWrapper'><div className='profileStrengthBadge'>{profileStrength}%</div><span className='profileStrengthText'>Profile Strength</span></div>  

  </div>
       </div>
  );
}

export default SidebarStudentProfile;