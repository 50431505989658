import './PortalSideBar.css';
import React, { Children, FC } from 'react';
import { Button, Grid, TextField, Tooltip, Typography } from '@mui/material';
import Logo from '../../Assets/LogoClr.svg'
import DashboardIcon from '../../Assets/dashboard.png'
import DashboardActiveIcon from '../../Assets/dashboard_active.png'
import BusinessIconActive from '../../Assets/business_center_active.png'
import BusinessIcon from '../../Assets/business_center.png'
import ProfileIcon from '../../Assets/profileIcon.png'
import ProfileIconActive from '../../Assets/profile_active.png'
import SettingIcon from '../../Assets/settings.png'
import SettingIconActive from '../../Assets/settings_active.png'
import AiLogo from '../../Assets/ai.png'
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import AddBoxIcon from '@mui/icons-material/AddBox';
interface PortalSideBarProps {
  activeTab:string
  changeTab?:(value:string)=>void
}
const PortalSideBar: FC<PortalSideBarProps> = ({activeTab,changeTab}) => {
  const nav = useNavigate()
  const [userName,setUserName] = React.useState<string>('')
  const user= React.useMemo(()=>{
    const user = window.localStorage.getItem('user')
    if(user){
      return JSON.parse(user)
    }
    else return null
  },[window])
const handleClickProfile = React.useCallback(()=>{
  const userData = window.localStorage.getItem('user')
  const role = userData?JSON.parse(userData)?.user?.roleId:''
  
  if(role===1){
    nav('/userProfile')
   
  }
  else if(role===4){
    return
  }
  else {
    nav('/recruiterProfile') 

  }
},[])
 const Logout= ()=>{
  window.localStorage.clear()
  nav('/signin')
 }
React.useEffect(()=>{
  const userData = window.localStorage.getItem('user');
 
    if(userData){
      const parsedData = JSON.parse(userData);
    
      setUserName(parsedData?.user?.name)
      
    }
   },[])

  return (
  <div className='portalSideBar'>
  <div><img className='Sidebarlogo' src={Logo} alt="logo" /></div>
  <div onClick={()=>user?.user?.roleId===1?nav('/student-dashboard'):nav('/recruiter-dashboard')}><img className='dashboardIcon' src={activeTab==='dashboard' ? DashboardActiveIcon : DashboardIcon} alt="icon" /></div>
  <div onClick={()=>user?.user?.roleId===1?nav('/job-listings'):nav('/portal')}>
    <img className='businessIcon' src={activeTab==='Amazone' || activeTab==='welcome' ?BusinessIconActive: BusinessIcon} alt="icon" /></div>
  {user?.user?.roleId===4  && <div onClick={()=>nav('/inviteUsers')}>
    <img className='settingeIcon' src={activeTab==='settings'?SettingIconActive: SettingIcon} alt="icon" />
    </div>}
    <img className='aiIcon' src={AiLogo} alt="icon" />
    <div className='seprationLine'></div>
    <div className='profileInitalBlock'><div onClick={handleClickProfile}  className='profileInital'>{userName[0]}</div></div>
    
    <div className='profileInitalBlock'><Tooltip  title="Log Out"><LogoutIcon onClick={()=>Logout()} htmlColor='#2468cc' /></Tooltip></div>
  </div>
  );
}

export default PortalSideBar;