import "../Portal/Portal.css"
import './job.css'
import '../SignUpPage/signup.css';
import React, { Children } from 'react';
import { Box, Button, Dialog, Grid, List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, MenuList, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import CreateJob from "Views/Job/CreateJob";
import LoadingBackdrop from "Common/LoadingBackdrop";
import { GlobalSnackbar } from "Common/GlobalSnackbar";
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from "@mui/x-charts";
import Paper from '@mui/material/Paper';
import ProfileBoy from 'Assets/profileBoy.svg'
import CloseIcon from '@mui/icons-material/Close';

import LinearScaleIcon from '@mui/icons-material/LinearScale';
import Logo from 'Assets/amazon.png'

import { useNavigate } from "react-router-dom";
import InviteCandidatePopup from "./InviteCandidatePopup";
import { daysBetween } from "Utils/utils";
import { baseURL } from "Views/StudentProfile/StudentProfile";
import ViewProfile from "./ViewProfile";
export interface JobDetailProps {
    jobData:any,
    closeJobDetails:VoidFunction,
    isSaved?:boolean
    isApplied?:boolean
    handleChangeSaveApply?:(type:string)=>void

  
  }
  
  export const JobDetails: React.FC<JobDetailProps> = ({
    jobData,
    closeJobDetails,
    isSaved,
    isApplied,
    handleChangeSaveApply
  }) => {
  const userList:any = [{name:'John Doe',experience:'7 yrs',designation:'Cloud Architect',rating:4.2,peer:3.7,showMenu:false},
    {name:'John Doe',experience:'7 yrs',designation:'Cloud Architect',rating:4.2,peer:3.7,showMenu:false},
  
    
  ]
  const nav = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [loading,setLoading]= React.useState<boolean>(false)
  const [userName,setUserName] = React.useState<string>('')
  const [selectedUserData,setSelectedUserData] = React.useState<any>()
  const [candidateMenuItem,setShowcandidateMenuItem] = React.useState<number>(-1)
  const [snackbarMessage, setSnackbarMessage] = React.useState<
string
>("")
const [candidateData, setCandidateData] = React.useState<
any
>()
const [allCandidatesOnPortal, setAllCandidatesonPortal] = React.useState<
any
>()
const [dialogType,setDialogType]=React.useState<'Share'|'Invite'|'Close'>('Share')
const [activeTab,setActiveTab] = React.useState<string>('Summary')
const [showInviteCandidate,setShowInviteCandidate]=React.useState<boolean>(false)

const onClickJobCard= React.useCallback((job:any,index:number)=>{
    console.log(job,index,'onClickJobCard')

},[])
const handleClose = () => {
  setAnchorEl(null);
  setShowcandidateMenuItem(-1)
}
 const renderCreateJobs=()=>{
  return (<div >
    <div className='titleTextPortal'>
  <div>        <div className='posting'>Posting</div>
  <div className='add_portal'>Create New</div></div>
    </div>
 <CreateJob />
    
    </div>)      
 }
 const jobAction = React.useCallback(async(type:string)=> {
  setLoading(true)
   const localJob = {...jobData}

    const userData = window.localStorage.getItem('user')
const token = userData?JSON.parse(userData)?.token:''
  return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/users/${type}`, {
    method: 'POST',
    headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + token},
    body: JSON.stringify({jobId:localJob?.JobDetails?.id})
  }) .then(response => {
    return response.json();
  }).then((data:any) => {
    handleChangeSaveApply?.(type)
  setLoading(false)
  setSnackbarMessage(data?.message)    
  }).catch((error)=>{
    console.log(error)
    setSnackbarMessage('Login Error')
    setLoading(false)
  })
},[jobData,handleChangeSaveApply])
 const handleToggleBtn=React.useCallback((value:string)=>{

  setActiveTab(value);
  
  },[])
  const userData= React.useMemo(()=>{
    const user = window.localStorage.getItem('user')
    if(user){
      return JSON.parse(user)
    }
    else return null
  },[window])
  const editJob=React.useCallback(()=>{
    nav('/new-posting',{ state: { jobToEdit: jobData } })
  },[])
  const inviteCandidate = React.useCallback((type:'Share'|'Invite'|'Close',message?:string
  )=>{
    setDialogType(type)
   setShowInviteCandidate(!showInviteCandidate)
   if(type==='Close' && !!message){
    setSnackbarMessage(message)
    getCandidates(true)

   }
  },[showInviteCandidate])
  const renderDetails = React.useCallback(()=>{
    return <>
    <div className="detailsOuter">
      {userData?.user?.roleId!==1 && <span className="editJobButton" onClick={()=>editJob()} >Edit</span>}
      <span className={userData?.user?.roleId!==1?"jobDescriptionLabel":'jobDescriptionLabelCandidate'}>Description</span>
      <div dangerouslySetInnerHTML={{__html:jobData?.jobDescSkills?.jobDescription}} />
      <span className={userData?.user?.roleId!==1?"jobDescriptionLabel":'jobDescriptionLabelCandidate'}>About Company</span>
      <div dangerouslySetInnerHTML={{__html:jobData?.aboutCompany?.description}} />
      <span className="jobDescriptionLabel">Industry</span>
      <span>{jobData?.JobDetails?.industry}</span>
      <span className="jobDescriptionLabel">Job Type</span>
      <span>{jobData?.JobDetails?.jobType}</span>
      <span className="jobDescriptionLabel">Skills</span>
      <span>{jobData?.jobDescSkills?.skills?.join(', ')}</span>
    </div>
    </>
  },[jobData,userData])
  const getCandidates = React.useCallback((addCandidate?:boolean)=> {
    setLoading(true)
    const userData = window.localStorage.getItem('user')
const token = userData?JSON.parse(userData)?.token:''
  return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/recruiters/getInviteCandidate?jobId=${jobData?.JobDetails?.id}&roleId=1`, {
    method: 'GET',
    headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + token},
  }) .then(response => {
    return response.json();
  }).then((data:any) => {
    if(data?.jobInviteCandidate){
      setAllCandidatesonPortal(data?.jobInviteCandidate)
    }
    if(!!data?.candidates || !!data?.jobInviteCandidate) {
  setCandidateData([...data?.candidates?.map((item:any)=>{
    return {email:item?.email,
      name:item?.name,
      createdAt:item?.createdAt,
      profilePic:ProfileBoy,
      experience:'Not Available',
      accountType:0,
      invitedBy:item?.user?.email
    }
  }),...data?.jobInviteCandidate?.map((item:any)=>{
    return {
      email:item?.userData?.email,
      userId:item?.userData?.id,
      name:item?.userData.firstName+' '+item?.userData?.lastName,
      createdAt:item?.userData?.createdAt,
      profilePic:item?.userData?.profilePic?baseURL+item?.userData?.profilePic:ProfileBoy,
      experience:item?.experience,
      accountType:1,
      invitedBy:item?.inviteUserBy?.email
    }
  })])
}
else{
  setCandidateData([])
  setSnackbarMessage(data?.message)
}

  setLoading(false)
  }).catch((error)=>{
    setSnackbarMessage(error?.message)
    setLoading(false)
  })
},[jobData])
React.useEffect(()=>{
  if(snackbarMessage!==''){
    const messageTimeout = setTimeout(()=>{
      setSnackbarMessage('')
      clearTimeout(messageTimeout)
    },5000)
  }
 },[snackbarMessage])
 const rejectCandidate = React.useCallback(async(rowData:any)=> {
  setLoading(true)
   const localJob = {...jobData}

    const userData = window.localStorage.getItem('user')
const token = userData?JSON.parse(userData)?.token:''
const id = userData?JSON.parse(userData)?.user?.id:null
  return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/recruiters/inviteJobStatusUpdate`, {
    method: 'PUT',
    headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + token},
    body: JSON.stringify({jobId:localJob?.JobDetails?.id,userId:rowData?.userId,status:'rejected'})
  }) .then(response => {
    return response.json();
  }).then((data:any) => {
    setShowcandidateMenuItem(-1)
    getCandidates()

  setLoading(false)
  setSnackbarMessage(data?.message)    
  }).catch((error)=>{
    console.log(error)
    setSnackbarMessage('Login Error')
    setLoading(false)
  })
},[jobData,handleChangeSaveApply])
   const renderCandidateOptions=React.useCallback((rowData:any)=>{

    return  ( 
    <Paper className="candidateMenuWrapper">
   <Menu 
     id="basic-menu"
     anchorEl={anchorEl}
     onClose={handleClose}
     MenuListProps={{
      'aria-labelledby': 'basic-button',
    }}
   open={candidateMenuItem>=0}
   > 
<MenuList>
  <MenuItem onClick={()=>rowData?.userData?.resume?window.open(baseURL+rowData?.userData?.resume,'_blank'):setSnackbarMessage('Resume not uploaded')}>
  Download Resume
  </MenuItem>
 {rowData?.accountType===1  &&  <MenuItem onClick={()=>setSelectedUserData(allCandidatesOnPortal?.filter((item:any)=>item?.userData?.email===rowData?.email)?.[0])}>
  View Profile
  </MenuItem> }
  

 {rowData?.accountType===1  && <MenuItem onClick={()=>rejectCandidate(rowData)}>
  Reject
  </MenuItem>}
  {/* <MenuItem onClick={()=>setShowcandidateMenuItem(-1)}>
  Close
  </MenuItem> */}
</MenuList>
</Menu>
    </Paper>)
   
   },[candidateMenuItem,anchorEl,allCandidatesOnPortal])
   const handleShowCandidateMenu=React.useCallback((event:any,index:number)=>{
                    
    setShowcandidateMenuItem(index)
    setAnchorEl(event.currentTarget)
    },[])
   const renderSummary=React.useCallback(()=>{
    return <div><div className="chatContainerOuter">
        <div className="chatContainer">
         <div className="charTextWrapper"><span className="chartTextHeading"></span> <span className="chartTextValue">Applied</span>  </div>
        <BarChart
  xAxis={[{ scaleType: 'band', data: ['Candidates Applied'] }]}
  series={[{ data: [4] }, { data: [1] }, { data: [ 5] }]}
  yAxis= {
    [{label:'Dates'}]
   }
  width={300}
  height={150}
  colors={['rgba(212,63,141,0.65)','rgba(2,80,197,0.25)']}
/>
        </div>
      
      
        <div className="chatContainer">
         <div className="charTextWrapper"><span className="chartTextHeading">0</span> <span className="chartTextValue">Hired</span>  </div>
        <BarChart
  xAxis={[{ scaleType: 'band', data: ['group A', 'group B'] }]}
  series={[{ data: [3, 0] }, { data: [0, 3] }, { data: [0, 0] }]}
  width={300}
  height={150}
  colors={['rgba(212,63,141,0.65)','rgba(2,80,197,0.25)']}

/>
        </div>
   
    
    </div>
   
<TableContainer style={{marginTop:40}} component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Candidate</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Experience</TableCell>
            <TableCell align="left">Invited By</TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
       
        <TableBody>
         
          {candidateData && candidateData?.map((row:any,index:number) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <div className="candidateOuter">
                  <div><img className="candiateImage" src={row.profilePic} /></div>
                  <div className="candidateTextOuter">
                  <span className="candiateName">{row?.userData?.name}</span>
                  <span className="candiateDesignation">{!!row?.designation}</span>
                  </div>
                </div>
              </TableCell>
              <TableCell align="left">{row?.email??'Not Available'}</TableCell>
              <TableCell align="left">{row?.experience}</TableCell>
              <TableCell align="left">{row?.invitedBy}</TableCell>
              <TableCell align="left">
                <Button
                  id="basic-button"
                  onClick={(event)=>handleShowCandidateMenu(event,index)}
                  className="candidateMenuIcon"
                >...
                  </Button>
                  
                 {index===candidateMenuItem && renderCandidateOptions(row)}
                </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
   },[candidateData,candidateMenuItem])
   React.useEffect(()=>{
    if(jobData){
      getCandidates()
    }
   },[jobData])
  return (
    <div className="jobDetails">

    

 {userData?.user?.roleId!==1?<div style={{marginLeft:40}}>  <Box sx={{ borderColor: 'divider',marginBottom:2,width:'maxContent' }}>
  <Tabs value={activeTab} >
    <Tab onClick={()=>handleToggleBtn("Summary")} classes={{root:'tabLabel'}} value={"Summary"} label="Summary"  />
    <Tab onClick={()=>handleToggleBtn("Details")} classes={{root:'tabLabel'}} value={"Details"} label="Details"  />
    <div className='closeIconJobDetail'>  
      <Button className='inviteJobButton'  onClick={()=>inviteCandidate('Invite')}>Invite Candidate</Button>
      <Button className='inviteJobButton'  onClick={()=>inviteCandidate('Share')}><ShareIcon htmlColor="white"/> Share Job</Button>
    <CloseIcon htmlColor='black'  onClick={()=>closeJobDetails()} /></div>
  </Tabs>

</Box>

   {activeTab==='Summary'? renderSummary():renderDetails()}</div>:
   <div style={{marginLeft:40,width:'100%'}}>
   <div className='saveApplyCandidate'> 
    <div className="jobDetailsCandidateTitleContainer">
    <img src={Logo} className='cardLogo'/>
    <div className="jobDetailsCandidateTitle">
      <span className="jobDetailsTitle">{jobData?.JobDetails?.jobTitle}</span>
      
      <span className="jobDetailsSubTitle">{jobData?.JobDetails?.company} | {daysBetween(jobData?.JobDetails?.createdAt)}</span>
      </div> 
      </div> 
   <div className='saveApplyCandidateRightPane'>
   <span  className='saveJob'onClick={()=>!isSaved && jobAction('saveJob')} >{isSaved?'Saved':'SAVE'}</span>
      <Button disabled={isApplied} className='inviteJobButton' onClick={()=>!isApplied && jobAction('applyJob')}  >{isApplied?'Applied':'Apply'}</Button>
    <CloseIcon htmlColor='black'  onClick={()=>closeJobDetails()} />

   </div>
    </div>
    <Box sx={{ borderColor: 'divider',marginBottom:2,width:'maxContent'}} >
  <Tabs value={'Details'} >
    <Tab classes={{root:'tabLabel'}} value={"Details"} label="Details"  />
   
  </Tabs>

</Box>
    {renderDetails()}
    </div>}
   
     <LoadingBackdrop isVisible={loading} />
<GlobalSnackbar position='bottom' message={snackbarMessage}  open={snackbarMessage!==''} />
{showInviteCandidate &&<InviteCandidatePopup type={dialogType} jobData={jobData} handleCloseDialog={(type,message)=>inviteCandidate('Close',message)} open={showInviteCandidate} />}
  <ViewProfile userData={selectedUserData} handleCloseDialog={(value)=>setSelectedUserData(undefined)} open={selectedUserData!==undefined} /> 
    </div>
  );
}

export default JobDetails;