
import React, { FC } from 'react'
import { Button, Dialog, Slide, TextField, Typography } from '@mui/material'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import './inviteUsers.css'
import LoadingBackdrop from 'Common/LoadingBackdrop';
import { GlobalSnackbar } from 'Common/GlobalSnackbar';
import InviteUsers from './InviteUsersPopup';
import InviteUsersPopup from './InviteUsersPopup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PortalSideBar from 'Common/PortalSideBar/PortalSideBar';
import SearchBar from 'Common/SearchBar/SearchBar';
import DeleteIcon from '@mui/icons-material/Delete';

interface InviteUserListProps {
   
}
const InviteUserList: FC<InviteUserListProps> = ({}) => {
   const nav = useNavigate()
   const [activeTab,setActiveTab] = React.useState<string>('settings')
const location = useLocation()
const [userData,setUserName]= React.useState<any>()
const [userList,setUserList]= React.useState<any>()
 
const [loading,setLoading]= React.useState<boolean>(false)
const [snackbarMessage, setSnackbarMessage] = React.useState<
string
>("")
const [showInvitePopup,setShowInvitePopup] = React.useState<boolean>(false)

const handleShowInviteUser=React.useCallback((value:boolean,callInvite?:boolean,message?:string)=>{
    setShowInvitePopup(value)
    setSnackbarMessage(message??'')
    if(callInvite){
        GetUsersList()
    }
 },[])
 const loggedInUser= React.useMemo(()=>{
  const user = window.localStorage.getItem('user')
  if(user){
    return JSON.parse(user)
  }
  else return null
},[window])
 const GetUsersList = React.useCallback(()=> {
    setLoading(true)
        const userData = window.localStorage.getItem('user')
    const token = userData?JSON.parse(userData)?.token:''
    return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/users/inviteUsersList`, {
      method: 'GET',
      headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + token},
    }) .then(response => {
      return response.json();
    }).then((data:any) => {
        
        setUserList(data?.inviteUser        )
        setLoading(false)

    }).catch((error)=>{
      setSnackbarMessage(error?.message)
      setLoading(false)
    })
  },[])
  const deleteUser = React.useCallback(async(id:number)=> {
    setLoading(true)
  
      const userData = window.localStorage.getItem('user')
  const token = userData?JSON.parse(userData)?.token:''
    return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/superAdmin/deleteUser/${id}`, {
      method: 'DELETE',
      headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + token},
    }) .then(response => {
      return response.json();
    }).then((data:any) => {
      GetUsersList()
    setSnackbarMessage(data?.message)    
    }).catch((error)=>{
      console.log(error)
      
      setLoading(false)
    })
  },[])
   React.useEffect(()=>{
    if(location?.state?.user ){
 setUserName(location?.state?.user)
    }
   },[location])
   function createData(
    name: string,
    email: string,
    designation: string,
    createdAt: string,
    isAccept: boolean,
  ) {
    return { name, email, designation, createdAt, isAccept };
  }
  const handleToggleBtn=React.useCallback((value:string)=>{

    setActiveTab(value);
    
    },[])

   const navigateUser = React.useCallback(()=>{
    if(userData?.user?.roleId===2){
        nav('/recruiterProfile')
    }
    else{
        nav('/userProfile')
    }
   },[userData])
   React.useEffect(()=>{
    const userData = window.localStorage.getItem('user')
    const token = userData?JSON.parse(userData)?.token:''
    if(userData){
      if(JSON.parse(userData)?.user?.roleId!==4){
        nav(JSON.parse(userData)?.user?.roleId===1?'/student-dashboard':'/portal')
      }
    }
    GetUsersList()
   },[])
   
   React.useEffect(()=>{
    if(snackbarMessage){
      const close = setTimeout(()=>{
        setSnackbarMessage('')
     clearTimeout(close)
      },5000)
    }
  },[snackbarMessage])
    return (
        <>
          <div className="portal">
      <PortalSideBar activeTab={activeTab} changeTab={handleToggleBtn} /> 
      <SearchBar /> 
      
      <div className='paddingClass magin-btm'> 
       
<div className='headerContainer'><div className='intiveListText'>Invited Users List</div> <Button onClick={()=>handleShowInviteUser(true)} className='inviteBtn'>Invite User</Button></div>

<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Designation</TableCell>
            <TableCell align="left">Created On </TableCell>
            <TableCell align="left">Accepted</TableCell>
            {loggedInUser?.user?.roleId===6 && <TableCell align="left">Delete</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {userList && userList?.map((row:any) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
       
              <TableCell align="left">{row.email}</TableCell>
              <TableCell align="left">{row?.role?.designation}</TableCell>
              <TableCell align="left">{row.createdAt.split("T")[0]}</TableCell>
              <TableCell align="left">{row.isAccept?'Accepted':'Not Accepted'}</TableCell>
              {loggedInUser?.user?.roleId===6 && <TableCell align="left"><DeleteIcon  onClick={()=>deleteUser(row.id)} fontSize='medium'  htmlColor='red' className='deleteuserIcon'  /></TableCell>}

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <GlobalSnackbar  message={snackbarMessage} open={snackbarMessage!==''} />

    <InviteUsersPopup handleCloseDialog={handleShowInviteUser} open={showInvitePopup}/>
    <LoadingBackdrop isVisible={loading} />
    </div> 


   
     
    </div>
  

</>
    )
}

export default InviteUserList