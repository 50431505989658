import "./StudentDashboard.css"
import '../SignUpPage/signup.css';
import React, { Children } from 'react';
import { redirect,useLocation,useNavigate } from 'react-router-dom';
import PortalSideBar from 'Common/PortalSideBar/PortalSideBar';
import DashboardDetails from "./DashboardDetails";
function StudentDashboard() {
  const nav = useNavigate()
  const location = useLocation()
  const [activeTab,setActiveTab] = React.useState<string>('dashboard')
  
  const handleToggleBtn=React.useCallback((value:string)=>{
  setActiveTab(value);
  },[])

 
  return (
    <div className="portal">
      <PortalSideBar activeTab={activeTab} changeTab={handleToggleBtn} /> 
   
      <div className="dashboardWrapper">
        <DashboardDetails />
        </div>
    </div>
  );
}

export default StudentDashboard;