

import React, { Children } from 'react';
import { Box, Button, Grid, Tab, Tabs, TextField, Typography } from '@mui/material';
import './Jobtab.css'
export interface JobtabProps {
    activeTab:string
    handleToggleBtn:(otp: string)=>void
    recommenedCount:number
    savedCount:number
    appliedCount:number

}
export const CandidateTab: React.FC<JobtabProps> = ({
    activeTab,
    handleToggleBtn,
    savedCount=0,
    recommenedCount=0,
    appliedCount=0
}) => {
  return (
<>
<Box sx={{}}>
  <Tabs value={activeTab} >
    <Tab onClick={()=>handleToggleBtn?.("Recommended")} classes={{root:'tabLabelClass'}} value={"Recommended"}     label={
              <React.Fragment>
                <span className='recommendedTab'>Recommended</span>  
                <span className='countNumber'>{recommenedCount}</span>
              </React.Fragment>
            }  />
    <Tab onClick={()=>handleToggleBtn?.("Saved")} classes={{root:'tabLabelClass'}} value={"Saved"}  label={
              <React.Fragment>
                <span className=''>Saved</span>  
                <span className='countNumber'>{savedCount}</span>
              </React.Fragment>
            }   />
              <Tab onClick={()=>handleToggleBtn?.("Applied")} classes={{root:'tabLabelClass'}} value={"Applied"}  label={
              <React.Fragment>
                <span className=''>Applied</span>  
                <span className='countNumber'>{appliedCount}</span>
              </React.Fragment>
            }   />

  </Tabs>
</Box>

</>
  );
}

export default CandidateTab;