import React, { Children } from 'react';
import LoadingBackdrop from "Common/LoadingBackdrop";
import { GlobalSnackbar } from "Common/GlobalSnackbar";
import Close from '../../Assets/close.png';
import AiImg from '../../Assets/aiBanner.svg';
import Stdimg from '../../Assets/stdIcon.png';
import { useNavigate } from "react-router-dom";
export interface JobDetailProps {
    
  }
  export const DashboardDetails: React.FC<JobDetailProps> = ({
    
  }) => {
 
  const nav = useNavigate()
  const [loading,setLoading]= React.useState<boolean>(false)
  const [userName,setUserName] = React.useState<string>('')
  
  
  return (
    <div className="dashboardDetails">
    <div className="dashboard_txt">Dashboard</div>
    <div className='dashboardChart'> 
    <div className="welcomeBanner">
        <img src={AiImg} alt="" className="ai_img" />
            <div className="Welcome_text">Hello {userName}, Welcome to R2O</div>
            <div className="right_telent">You can apply for the job of your interest.</div>
            <img onClick={()=>nav('/userProfile')} src={Close} alt="" className="close_img" />
        </div>
   </div>
 
 <div className='newOpningCard'>
 <img src={Stdimg} alt="" className="stdImg" />
<div className='stdTxt'>
  <div className='countCls'>2</div>
  <div className='new-openings'>New Openings</div>
</div>
 </div>
 <div className='newOpningCard'>
 <img src={Stdimg} alt="" className="stdImg" />
<div className='stdTxt'>
  <div className='countCls'>3</div>
  <div className='new-openings'>Job Applied</div>
</div>
 </div>
     <LoadingBackdrop isVisible={loading} />

    </div>
  );
}

export default DashboardDetails;