import './Jobcard.css';
import React, { Children } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MenuList, Paper } from '@mui/material';
import Logo from 'Assets/company.avif'
import SingleLine from 'Utils/SingleLine';
import { baseURL } from 'Views/StudentProfile/StudentProfile';
export interface JobCardProps {
  jobData:any
  jobIndex:number
  onJobClick:(jobDetail:any,index:number)=>void
  onMenuItemClick?:(action:string,event?:any,jobData?:any)=>void,
  selectedTab?:string

}
export const Jobcard: React.FC<JobCardProps> = ({
  jobData,
  jobIndex,
  onJobClick,
  onMenuItemClick,
  selectedTab
}) => {
  const [activeMenuId,setActiveMenuId]=React.useState<number>(0)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const userData= React.useMemo(()=>{
    const user = window.localStorage.getItem('user')
    if(user){
      return JSON.parse(user)
    }
    else return null
  },[window])
 const handleOpenMenu = React.useCallback((event:any,menuId?:number)=>{
  event.stopPropagation()
  setActiveMenuId(menuId??0)
  setAnchorEl(event?.currentTarget)

 },[])
 const handleClose = (event:any)=>{
  event.stopPropagation()
  setActiveMenuId(0)

 }

 const handleClick = React.useCallback((action:string,event:any)=>{

  event.stopPropagation()
  
  onMenuItemClick?.(action,event,jobData)
  setActiveMenuId(0)
 },[onMenuItemClick,jobData])
  return (
  <Paper onClick={()=>onJobClick?.(jobData,jobIndex)} className='JobcardDetail'>
      
          <Menu 
     id="basic-menu"
     anchorEl={anchorEl}
     onClose={handleClose}
     MenuListProps={{
      'aria-labelledby': 'basic-button',
    }}
   open={activeMenuId===jobData?.JobDetails?.id}>
    <MenuList>
        <MenuItem onClick={(e)=>handleClick('Edit',e)}>Edit</MenuItem>
        <MenuItem onClick={(e)=>handleClick('Duplicate',e)}>Duplicate</MenuItem>
        {selectedTab!=='Draft' &&<MenuItem onClick={(e)=>handleClick('SaveDraft',e)}>Save as Draft</MenuItem>}
        {(selectedTab==='Active' || selectedTab==='Draft') && <MenuItem onClick={(e)=>handleClick('InActive',e)}>Mark InActive</MenuItem>}
        {(selectedTab==='InActive'||selectedTab==='Draft') && <MenuItem onClick={(e)=>handleClick('Active',e)}>Mark Active</MenuItem>}
        <MenuItem onClick={(e)=>handleClick('Delete',e)}>Delete</MenuItem>
        </MenuList>
        </Menu>
    {userData?.user?.roleId!==1 && <MoreVertIcon id="basic-button" onClick={(e)=>handleOpenMenu(e,jobData?.JobDetails?.id)} className='moreCardIcon' htmlColor='#19202D' />}
     <img src={!!jobData?.companyDetails?.companyLogo?baseURL+jobData?.companyDetails?.companyLogo: Logo} className='cardLogo'/>
    <div className='jobName'><SingleLine text={jobData?.JobDetails?.jobTitle} limit={28} /> </div>
    <div className='codeLine'><SingleLine text={jobData?.JobDetails?.company} limit={30} /> </div>
    <div className='profileName'><SingleLine text={jobData?.JobDetails?.jobFunction} limit={30} /></div>
    <div className='experiance'><SingleLine text={jobData?.JobDetails?.experience} limit={30} /></div>
    <div className='dateValue'> {jobData?.JobDetails?.createdAt ? new Date(jobData?.JobDetails?.createdAt).toDateString() : "---"}</div>
  
  </Paper>
  );
}

