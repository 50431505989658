

import React, { Children } from 'react';
import { Box, Button, Grid, Tab, Tabs, TextField, Typography } from '@mui/material';
import './Jobtab.css'
export interface JobtabProps {
    activeTab:string
    handleToggleBtn:(otp: string)=>void
    activeCount:number
    draftCount:number
    inactiveCount:number

}
export const Jobtab: React.FC<JobtabProps> = ({
    activeTab,
    handleToggleBtn,
    activeCount=0,
    draftCount=0,
    inactiveCount=0
}) => {
  return (
<>
<Box sx={{}}>
  <Tabs value={activeTab} >
    <Tab onClick={()=>handleToggleBtn?.("Active")} classes={{root:'tabLabelClass'}} value={"Active"}     label={
              <React.Fragment>
                <span className=''>Active</span>  
                <span className='countNumber'>{activeCount}</span>
              </React.Fragment>
            }  />
    <Tab onClick={()=>handleToggleBtn?.("InActive")} classes={{root:'tabLabelClass'}} value={"InActive"}  label={
              <React.Fragment>
                <span className=''>InActive</span>  
                <span className='countNumber'>{inactiveCount}</span>
              </React.Fragment>
            }   />
    <Tab onClick={()=>handleToggleBtn?.("Draft")} classes={{root:'tabLabelClass'}} value={"Draft"}  label={
              <React.Fragment>
                <span className=''>Draft</span>  
                <span className='countNumber'>{draftCount}</span>
              </React.Fragment>
            }   />

  </Tabs>
</Box>

</>
  );
}

export default Jobtab;