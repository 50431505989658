
import React, { FC } from 'react'
import { Button, Dialog, MenuItem, Select, Slide, TextField, Typography } from '@mui/material'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import './job.css'
import LoadingBackdrop from 'Common/LoadingBackdrop';
import { GlobalSnackbar } from 'Common/GlobalSnackbar';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { baseURL } from 'Views/StudentProfile/StudentProfile';
import ProfilePic from 'Assets/profilePic.png'

interface InviteUserProps {
   
    userData:any
    handleCloseDialog?:(value:boolean)=>void
    open?:boolean
}
const ViewProfile: FC<InviteUserProps> = ({userData,handleCloseDialog,open}) => {
   const nav = useNavigate()
const location = useLocation()
const [loading,setLoading]= React.useState<boolean>(false)
const [snackbarMessage, setSnackbarMessage] = React.useState<
string
>("")





   React.useEffect(()=>{
    if(snackbarMessage!==''){
      const messageTimeout = setTimeout(()=>{
        setSnackbarMessage('')
        clearTimeout(messageTimeout)
      },5000)
    }
   },[snackbarMessage])
    return (
        <Dialog
        open={open??false}
        fullWidth
        fullScreen
        >
    <Slide 
     direction="left"
                in={true}

                mountOnEnter
                unmountOnExit
                timeout={0}
                 ><div className='inviteUserContent'>
                    <div className='inviteHeadingContainer'><span className='inviteHeading'>Candidate Profile</span>
                    <CloseIcon className='closeIconInvite' onClick={()=>handleCloseDialog?.(false)} htmlColor='black' />
                    </div>
                    {/* <img className='selectProfileImage' src={!!userData?.userData?.profilePic?baseURL+userData?.userData?.profilePic: ProfilePic}/> */}
             
                    {/* <div className="candidateTextOuter">
                  <span className="candiateName">{userData?.userData?.firstName+' '+userData?.userData?.lastName}</span>
                  <span className="candiateDesignation">{!!userData?.designation}</span>
                  </div>
                  <div className="candidateTextOuter">
                  <span className="candiateName">Designation - {userData?.designation}</span>
                  </div>
                  <div className="candidateTextOuter">
                  <span className="candiateName">Experience - {userData?.experience}</span>
                  </div>
                  <div className="candidateTextOuter">
                  <span className="candiateName">Phone - {userData?.userData?.phone}</span>
                  </div>
                  <div className="candidateTextOuter">
                  <span className="candiateName">Email - {userData?.userData?.email}</span>
                  </div>
                  <div className="candidateTextOuter">
                  <span className="candiateName">LinkedeIn Profile - {userData?.userData?.linkedeinProfile??'Not Available'}</span>
                  </div> */}

<div className="card">
    <div className="card-left">
      <img src="https://via.placeholder.com/200" alt="Profile Picture" className="profile-pic"/>
    </div>
    <div className="card-right">
      <h3>{userData?.userData?.firstName+' '+userData?.userData?.lastName}</h3>
      <p><span className="key">Candidate Type:</span>  {userData?.userData?.carrierType ? userData?.userData?.carrierType :"---"}</p>
      
      <p><span className="key">Region:</span>  {userData?.userData?.region ? userData?.userData?.region :"---"}  </p>
      <p><span className="key">Country:</span>  {userData?.userData?.country ? userData?.userData?.country :"---"}  </p>
      <p><span className="key">Phone:</span>{userData?.userData?.phone ? userData?.userData?.phone :"---"}   </p>
      <p><span className="key">Email:</span> {userData?.userData?.email ? userData?.userData?.email :"---"}</p>
      <p><span className="key">Linkedin Profile:</span> {userData?.userData?.linkedinProfile ? userData?.userData?.linkedinProfile :"---"}</p>
      {userData?.userData?.workExperience.length && <>
        <h4>Work Experience:</h4>
      {userData?.userData?.workExperience.map((item: any)=>{
        return <ul >
        <li><span className="key">Title:</span> {item?.title}</li>
        <li><span className="key">Currently Working here:</span> {item?.currentlyWork}</li>
      </ul> 
      })}
      </>}
      {userData?.userData?.workPreference && <>
        <h4>Work Preference:</h4>
      <ul>
        <li><span className="key">Location:</span> {userData?.userData?.workPreference?.prefWorkLocation}</li>
        <li><span className="key">Preference Job Type:</span> {userData?.userData?.workPreference?.prefJobType}</li>
        <li><span className="key">Desired Salary:</span>  {userData?.userData?.workPreference?.desired}</li>
        <li><span className="key">Notice Period:</span> {userData?.userData?.workPreference?.NoticePeriod}</li>
      </ul> 
      </>}
     
      {userData?.userData?.coverLetter && <a href={baseURL+userData?.userData?.coverLetter} className="download-link" download>Download Cover Letter</a>}
      {userData?.userData?.resume && <a href={baseURL+userData?.userData?.resume} className="download-link" download>Download Resume</a>}
    </div>
  </div>
                  
                </div></Slide>
                <LoadingBackdrop isVisible={loading} />
                <GlobalSnackbar  message={snackbarMessage} open={snackbarMessage!==''} />

        </Dialog>
    )
}

export default ViewProfile