import React, { Children } from 'react';
import { MenuItem, MenuList, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';

import CreateJob from "Views/Job/CreateJob";
import LoadingBackdrop from "Common/LoadingBackdrop";
import { GlobalSnackbar } from "Common/GlobalSnackbar";
import { BarChart } from '@mui/x-charts/BarChart';

import { LineChart } from '@mui/x-charts/LineChart';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
export interface JobDetailProps {
    
  }
  export const DashboardDetails: React.FC<JobDetailProps> = ({
    
  }) => {
  const userList:any = [{name:'John Doe',experience:'7 yrs',designation:'Cloud Architect',rating:4.2,peer:3.7,showMenu:false},
    {name:'John Doe',experience:'7 yrs',designation:'Cloud Architect',rating:4.2,peer:3.7,showMenu:false},
    {name:'John Doe',experience:'7 yrs',designation:'Cloud Architect',rating:4.2,peer:3.7,showMenu:false},
    
  ]
  const nav = useNavigate()
  const [loading,setLoading]= React.useState<boolean>(false)
  const [userName,setUserName] = React.useState<string>('')
  const [candidateMenuItem,setShowcandidateMenuItem] = React.useState<number>(-1)
  const [snackbarMessage, setSnackbarMessage] = React.useState<
string
>("")
const [activeTab,setActiveTab] = React.useState<string>('Summary')


const onClickJobCard= React.useCallback((job:any,index:number)=>{
    console.log(job,index,'onClickJobCard')

},[])

 const renderCreateJobs=()=>{
  return (<div >
    <div className='titleTextPortal'>
  <div>        <div className='posting'>Posting</div>
  <div className='add_portal'>Create New</div></div>
    </div>
 <CreateJob />
    
    </div>)      
 }
 const handleToggleBtn=React.useCallback((value:string)=>{

  setActiveTab(value);
  
  },[])
 
  


   const renderSummary=React.useCallback(()=>{
    const chartsParams = {
        margin: { bottom: 20, left: 25, right: 5 },
        height: 120,
        width:250
      };
    return <div><div className="chatContainerOuter">
        <div className="chatContainer">
         <div className="charTextWrapper"><span className="chartTextHeading">38</span> <span className="chartTextValue">Self Interview</span>  </div>
        <BarChart
  xAxis={[{ scaleType: 'band', data: ['group A', 'group B', 'group C'] }]}
  series={[{ data: [4, 3, 5] }, { data: [1, 6, 3] }, { data: [2, 5, 6] }]}
  width={300}
  height={150}
  colors={['rgba(212,63,141,0.65)','rgba(2,80,197,0.25)']}
/>
        </div>
      
      
        <div className="chatContainer">
         <div className="charTextWrapper"><span className="chartTextHeading">0</span> <span className="chartTextValue">Hired</span>  </div>
        <BarChart
  xAxis={[{ scaleType: 'band', data: ['group A', 'group B', 'group C'] }]}
  series={[{ data: [3, 0, 0] }, { data: [0, 3, 0] }, { data: [0, 0, 3] }]}
  width={300}
  height={150}
  colors={['rgba(212,63,141,0.65)','rgba(2,80,197,0.25)']}

/>
        </div>
        <div className="chatContainer">
         <div className="charTextWrapper"><span className="chartTextHeading">0</span> <span className="chartTextValue">Conversion Rate</span>  </div>
         <LineChart
        {...chartsParams}
        series={[
          {
            data: [15, 23, 18, 19, 13],
            label: '',
            color:'rgba(232,103,114,0.1)',
          },
        ]}

      />
        </div>
    
    </div>
 
    </div>
   },[candidateMenuItem])
  return (
    <div className="dashboardDetails">
    <div className="dashboard_txt">Dashboard</div>
    <div className='dashboardChart'> 
   {renderSummary()}
   </div>
   <div className='effort_txt'>20.25% applicants are recommended saving 80% of effort</div>
   <LineChart
      xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
      series={[
        {
          data: [2, 5.5, 2, 8.5, 1.5, 5],
        },
      ]}
      width={500}
      height={300}
    />
     <LoadingBackdrop isVisible={loading} />
<GlobalSnackbar position='bottom' message={snackbarMessage}  open={snackbarMessage!==''} />
    </div>
  );
}

export default DashboardDetails;