

import React, { Children } from 'react';
export interface DashboardSideBarProps {
 

}
export const DashboardSideBar: React.FC<DashboardSideBarProps> = ({
   
}) => {

  const [userName,setUserName] = React.useState<string>('')
  React.useEffect(()=>{
    const userData = window.localStorage.getItem('user');
   
      if(userData){
        const parsedData = JSON.parse(userData);
      
        setUserName(parsedData?.user?.name)
        
      }
     },[])

  return (
<>
<div >
      <div className='dashSidebarWrapper'>
           <div className='welcome-text'>Welcome back <span className='ttC'>{userName}</span> ,<br/>
Below are the updates<br/>
since your last visit<br/><br/><br/>

<span className='ttC'>18 </span>New Candidate<br/>
Recommendation<br/><br/><br/>
<span className='ttC'>5 </span> Candiates moved to<br/>
next interview level</div>
        </div>
        
        </div>
</>
  );
}

export default DashboardSideBar;