
import React, { FC } from 'react'
import { Button, Checkbox, Chip, Dialog, MenuItem, Select, Slide, Stack, TextField, Typography } from '@mui/material'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import './job.css'
import LoadingBackdrop from 'Common/LoadingBackdrop';
import { GlobalSnackbar } from 'Common/GlobalSnackbar';
import clsx from 'clsx'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, Bold, Essentials, Italic,FontFamily, Paragraph, Undo, FontSize, FontColor, Heading, Link, Underline, FontBackgroundColor,  IndentBlock, Indent, List, FontSizeUI, Font } from 'ckeditor5';
import { SlashCommand } from 'ckeditor5-premium-features';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUpload from 'Assets/fileUpload.png'

import 'ckeditor5/ckeditor5.css';
import 'ckeditor5-premium-features/ckeditor5-premium-features.css';
import { FormatListBulleted, FormatListNumbered } from '@mui/icons-material';
import { ck } from 'Common/Constants';
interface CreateJobProps {
   
}
const CreateJob: FC<CreateJobProps> = ({}) => {
   const nav = useNavigate()
const location = useLocation()
const [userData,setUserName]= React.useState<any>()
const editorRef = React.useRef<any>();
const [documentDesc,setDocument] = React.useState<any>()
const [loading,setLoading]= React.useState<boolean>(false)
const [snackbarMessage, setSnackbarMessage] = React.useState<
string
>("")
const [isForEdit,setIsForEdit]= React.useState<boolean>(false)

const [newSkill,setNewSkill] = React.useState<string>('')

const [jobData,setJobData] = React.useState<any>({

  JobDetails: {
    company: "",
    jobTitle: "",
    jobLocation: "",
    jobType: "",
    experience: "",
    industry: "",
    jobFunction: "",
    salary:'',
    status:'Active'
},
jobDescSkills: {
    jobDescription: "",
    descriptionDoc:"",
    skills: []
},
aboutCompany: {
    description: "",
    logoVideo: []
},
interviewDetails: {
    Joblocation: [{location:'',position:1}],
    position: 5,
    numberInterview: [
        {
            interviewerName: "",
            description: ""
        }
    ]
},
others: [
    {
        postAsJobInfo: false,
        parameterName: "",
        parameterValue:""
    }
]
})


   React.useEffect(()=>{
    if(location?.state?.user ){
 setUserName(location?.state?.user)
    }
    if(location?.state?.jobToEdit){
      setJobData(location?.state?.jobToEdit)
      setIsForEdit(true)
    }
    else{
      setLoading(true)
        const userData = window.localStorage.getItem('user')
const token = userData?JSON.parse(userData)?.token:''
getUserDetails(token)
    }
   },[location])
   React.useEffect(()=>{
    if(snackbarMessage!==''){
      const messageTimeout = setTimeout(()=>{
        setSnackbarMessage('')
        clearTimeout(messageTimeout)
      },5000)
    }
   },[snackbarMessage])
   const addSkill = React.useCallback((skill:string)=>{
    if(skill?.trim()?.length===0) return
    let localJobData = {...jobData}
    setJobData({...localJobData,jobDescSkills:{...localJobData?.jobDescSkills,skills:[...localJobData?.jobDescSkills?.skills,skill]}})
  setNewSkill('')
  },[jobData])
const handleChangeInput =React.useCallback((event:any,type:string,key?:string,index?:number)=>{
  const value = event?.target?.value
  const localJobData = {...jobData}
//   JobDetails: {
//     company: "",
//     jobTitle: "",
//     jobLocation: "",
//     jobType: "",
//     experience: "",
//     industry: "",
//     jobFunction: ""
// },
if(type==='Skill'){
  setNewSkill(event?.target?.value)
}
if(type==='JobDetails'){
  setJobData({...localJobData,JobDetails:{...localJobData?.JobDetails,[key!]:value}})
}
if(type==='parameterValue' || type==='parameterName' || type==='postAsJobInfo'){
  setJobData({...localJobData,others:localJobData?.others?.map((item:any,indexInner:number)=>{

    if(index===indexInner){
      return {...item,[type]:event?.target?.value}
    }
    else return item
  })})
}
if(type==='interviewDetails'){
  
  setJobData({...localJobData,interviewDetails:{...localJobData?.interviewDetails,numberInterview:localJobData?.interviewDetails?.numberInterview?.map((item:any,indexInner:number)=>{
    if(index===indexInner){
      return {...item,[key!]:event?.target?.value}
    }
    else return item
  })}})
}
if(type==='jobDescription'){
  setJobData({...localJobData,jobDescSkills:{...localJobData?.jobDescSkills,jobDescription:event}})

}
if(type==='aboutDescription'){
  setJobData({...localJobData,aboutCompany:{...localJobData?.aboutCompany,description:event}})

}

if(type==='jobOpening'){
  setJobData({...localJobData,interviewDetails:{...localJobData?.interviewDetails,Joblocation:localJobData?.interviewDetails?.Joblocation?.map((item:any,indexInner:number)=>{
    if(index===indexInner){
      return {...item,[key!]:event?.target?.value}
    }
    else return item
  })}})
}


   
},[jobData])
const handleSelect =React.useCallback((value:any,type:string)=>{
  const localJobData = {...jobData}
  setJobData({...localJobData,JobDetails:{...localJobData.JobDetails,[type]:value}})

  
},[jobData])
const deleteSkill=React.useCallback((index:number)=>{
  const localJobData = {...jobData}
  const newSkills = [
    ...localJobData?.jobDescSkills?.skills.slice(0,index),
    ...localJobData?.jobDescSkills?.skills.slice(index+1)
  ]

  setJobData({...localJobData,jobDescSkills:{...localJobData?.jobDescSkills,skills:newSkills}})


},[jobData])
const getUserDetails = React.useCallback(async(userToken:string)=> {

  return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/recruiters/getRecruiterData`, {
    method: 'GET',
    headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + userToken},

  }) .then(response => {
    return response.json();
  }).then((data:any) => {
    console.log(jobData,data?.companyName)
    setJobData({...jobData,JobDetails:{...jobData?.JobDetails,company:data?.data?.companyName}})
    setLoading(false)

  }).catch((error)=>{
    setLoading(false)

    setSnackbarMessage(error.message)
  })
},[])
const addMoreContent = React.useCallback((type?:string)=>{
  if(type==='Joblocation'){
  const JobLocationObject = {location:'',position:0}
  setJobData({...jobData,interviewDetails:{...jobData.interviewDetails,Joblocation:[...jobData?.interviewDetails?.Joblocation,JobLocationObject]}})
  }
  else if(type==='numberInterview') {
    const numberInterviewObject = {
      interviewerName: "",
      description: ""
  }
    setJobData({...jobData,interviewDetails:{...jobData.interviewDetails,numberInterview:[...jobData?.interviewDetails?.numberInterview,numberInterviewObject]}})
  }
  else{
 const othersObject = {
  postAsJobInfo: false,
  parameterName: "",
  parameterValue:""
}
setJobData({...jobData,others:[...jobData?.others,othersObject]})

  }
},[jobData])
const handleUploadFile = React.useCallback(async(file:any,type:string,isForEdit?:boolean,jobId?:string)=> {
  const userData = window.localStorage.getItem('user')
const token = userData?JSON.parse(userData)?.token:''
const formData = new FormData();
formData.append(type,file)
formData.append('jobId',jobId??'' )
return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/users/uploadFile`, {
method: 'POST',
headers:{'Access-Control-Allow-Origin':'*',Authorization:'Bearer ' + token},
body:formData

}) .then(response => {
return response.json();
}).then((data:any) => {
  if(data?.message==='File upload error'){
    setSnackbarMessage(data?.message)
  
  }
  else{
    if(isForEdit){
      if(data?.file){
        setSnackbarMessage(data?.message)
        const localJobData = {...jobData}
        if(type==='description'){
          setJobData({...localJobData,jobDescSkills:{...localJobData?.jobDescSkills,descriptionDoc:data?.file}})
        
        }
        
        
        }
    }
    else{
      nav('job-listings')
    }
  }
 

  setLoading(false)

}).catch((error)=>{
setSnackbarMessage(error.message)
setLoading(false)
})
},[jobData,documentDesc])
const handleUploadDoc=(event:any,type:string)=>{
  const fileSize:number = Number(((event?.target?.files?.[0].size/1024)/1024).toFixed(4))
  
    if(fileSize > 5) {
      setSnackbarMessage('Only pdf. Max size 5 MB allowed')
     return
   }
   else{
    if(isForEdit){
      setLoading(true)

      handleUploadFile(event?.target?.files?.[0],type,true,jobData?.JobDetails?.id)
    }
    else {
      setDocument(event?.target?.files?.[0])
    }
    
   }
  }
const deleteContent = React.useCallback((index:number,type?:string)=>{

  if(type==='Joblocation' || type==='numberInterview'){
    const localJobData = {...jobData}

const newJobData = [
  ...localJobData?.interviewDetails?.[type].slice(0,index),
  ...localJobData?.interviewDetails?.[type].slice(index+1)
]
setJobData({...localJobData,interviewDetails:{...localJobData.interviewDetails,[type]:newJobData}})

    }
  
    else{
      const localJobData = {...jobData}

      const newJobData = [
        ...localJobData?.others?.slice(0,index),
        ...localJobData?.others?.slice(index+1)
      ]
      setJobData({...jobData,others:newJobData})
      
  
    }
},[jobData])
const deleteFile=React.useCallback((type:string)=>{
  const localJobData = {...jobData}
  if(type==='description'){
    if(isForEdit){
      setJobData({...localJobData,jobDescSkills:{...localJobData?.jobDescSkills,descriptionDoc:''}})

    }
    else{
      setDocument(null)

    }

  }


},[jobData,isForEdit])
const createJob = React.useCallback((status:string)=>{
  if(jobData?.JobDetails?.company?.trim()==='' 
  || jobData?.JobDetails?.experience?.trim()==='' 
  || jobData?.JobDetails?.industry?.trim()==='' 
  || jobData?.JobDetails?.jobFunction?.trim()==='' 
  || jobData?.JobDetails?.jobLocation?.trim()==='' 
  || jobData?.JobDetails?.jobTitle?.trim()==='' 
  || jobData?.JobDetails?.jobType?.trim()==='' 
  || jobData?.JobDetails?.salary?.trim()==='' 
  || jobData?.jobDescSkills?.jobDescription?.trim()==='' 
  || jobData?.jobDescSkills?.skills?.length===0 
  || jobData?.aboutCompany?.description?.trim()==='' 
  || jobData?.interviewDetails?.Joblocation?.some((item:any)=>item?.location?.trim()?.length===0 || item?.position?.length===0)
  || jobData?.interviewDetails?.numberInterview?.some((item:any)=>item?.description?.trim()?.length===0 || item?.interviewerName?.trim()?.length===0)
){
setSnackbarMessage('Fill mendatory fields')
  }
  else {
    if(isForEdit){
  editJobAPI(status)
    }
    else{
      createJobAPI(status)
    }
    
  }
},[jobData,isForEdit])
const renderBasicDetails=React.useMemo(()=>{
return (<div className='jobMainWrapper'>
    <div className='educationIndex'>1</div>
     <div className='jobPartContainer'>
            <span className='jobPartContainerLabel'>Basic Job Details</span>
          <div className='inputRow'>
          <div className='jobInputContainer'>
            <span className='jobCompanyInputLabel'>Company <sup>*</sup></span>
            <TextField required classes={{root:"jobCompanyInput"}} value={jobData?.JobDetails?.company} onChange={(e)=>handleChangeInput(e,'JobDetails','company')}  type='text' />
            </div>
            <div className='jobInputContainer'>
            <span className={clsx('jobCompanyInputLabel')}>Job Title<sup>*</sup></span>
            <TextField required  classes={{root:clsx("jobCompanyInputTitle",'jobCompanyInput')}} 
            value={jobData?.JobDetails?.jobTitle}
            onChange={(e)=>handleChangeInput(e,'JobDetails','jobTitle')}  type='text' />
            </div>
          </div>
    
          <div className='inputRow'>
          <div className='jobInputContainer'>
            <span className='jobCompanyInputLabel'>Job Location <sup>*</sup></span>
            <TextField required  classes={{root:"jobCompanyInput"}}
                        value={jobData?.JobDetails?.jobLocation}

            onChange={(e)=>handleChangeInput(e,'JobDetails','jobLocation')}  type='text' />
            </div>
            <div className='jobInputContainer'>
            <span className={clsx('jobCompanyInputLabel')}>Job Type<sup>*</sup></span>
           
            <Select
            required
        className='jobCompanyInput'
        value={jobData?.JobDetails?.jobType
        }
        onChange={(e)=>handleSelect(e?.target?.value,'jobType')}
      >
        


        <MenuItem value={'Full-time'}>Full-time</MenuItem>
        <MenuItem value={'Part-time'}>Part-time</MenuItem>
        <MenuItem value={'Contract'}>Contract</MenuItem>
        <MenuItem value={'Internship'}>Internship</MenuItem>
      </Select>
            </div>
            <div className='jobInputContainer'>
            <span className={clsx('jobCompanyInputLabel')}>Experience<sup>*</sup></span>
            <TextField  required classes={{root:clsx('jobCompanyInput')}} 
            value={jobData?.JobDetails?.experience}
            onChange={(e)=>handleChangeInput(e,'JobDetails','experience')}  type='text' />
            </div>
          </div>
    
          <div className='inputRow'>
          <div className='jobInputContainer'>
            <span className='jobCompanyInputLabel'>Industry<sup>*</sup></span>
         
      <TextField required  classes={{root:"jobCompanyInput"}}
                        value={jobData?.JobDetails?.industry}

            onChange={(e)=>handleChangeInput(e,'JobDetails','industry')}  type='text' />
            </div>
            <div className='jobInputContainer'>
            <span className={clsx('jobCompanyInputLabel')}>Job Function<sup>*</sup></span>
   
      <TextField required  classes={{root:"jobCompanyInput"}}
                        value={jobData?.JobDetails?.jobFunction}

            onChange={(e)=>handleChangeInput(e,'JobDetails','jobFunction')}  type='text' />
            </div>
            <div className='jobInputContainer'>
            <span className={clsx('jobCompanyInputLabel')}>Estimated Salary (in $)<sup>*</sup></span>
      
      <TextField required  classes={{root:"jobCompanyInput"}}
                        value={jobData?.JobDetails?.salary}

            onChange={(e)=>handleChangeInput(e,'JobDetails','salary')}  type='number' />
              </div>
          </div>
        </div>
    </div>)

},[jobData])
const createJobAPI = React.useCallback(async(status:string)=> {
  setLoading(true)
   const localJob = {...jobData}

    const userData = window.localStorage.getItem('user')
const token = userData?JSON.parse(userData)?.token:''
  return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/recruiters/createJob`, {
    method: 'POST',
    headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + token},
    body: JSON.stringify({...localJob,JobDetails:{...localJob?.JobDetails,status:status}})
  }) .then(response => {
    return response.json();
  }).then((data:any) => {
  if(documentDesc){
    handleUploadFile(documentDesc,'document',false,data?.job?.id)
  }
  else{
    nav('/job-listings') 
  }
 
  }).catch((error)=>{
    console.log(error)
    setSnackbarMessage('Login Error')
    setLoading(false)
  })
},[jobData,documentDesc])
const editJobAPI = React.useCallback(async(status:string)=> {
  setLoading(true)
   const localJob = {...jobData}

    const userData = window.localStorage.getItem('user')
const token = userData?JSON.parse(userData)?.token:''
  return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/recruiters/updateJob/${localJob?.JobDetails?.id}`, {
    method: 'PUT',
    headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + token},
    body: JSON.stringify({...localJob,JobDetails:{...localJob?.JobDetails,status:status}})
  }) .then(response => {
    return response.json();
  }).then((data:any) => {
    
  nav('/job-listings')  
  }).catch((error)=>{
    console.log(error)
    setSnackbarMessage('Login Error')
    setLoading(false)
  })
},[jobData])
const renderDescription=React.useMemo(()=>{
  const descDoc = isForEdit?jobData?.jobDescSkills?.descriptionDoc:documentDesc
    return (<div className='jobMainWrapper'>
        <div className='educationIndex'>2</div>
         <div className='jobPartContainer'>
                <span className='jobPartContainerLabel'>Job Description & Skills</span>
                <div className='inputRow'>
          <div className='jobInputContainer'>
            <span className='jobCompanyInputLabel'>Job Description <sup>*</sup></span>
            <CKEditor
            editor={ ClassicEditor }
         
            config={ {
              heading: {
                options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                    
                    { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                    { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' }
                ]
            },
            menuBar: {
              isVisible: true
          },
                toolbar: {
        icon: 'text',
                    items: ['heading',
            'bold',
            'italic',
            'underline',
            'paragraph',
            'essentials',
            'fontSize',
            'fontFamily',
            'fontBackgroundColor',
            'fontColor',
            'link',
            
            'outdent', 'indent',
            'bulletedList', 'numberedList' ,
            'undo','redo',
        
             ],
            },
                plugins: [
                  Heading,Bold,Italic,Underline,Paragraph, Essentials,FontSize,FontFamily,
                  FontBackgroundColor,FontColor,Link,Indent, IndentBlock,List
                ],
                licenseKey: 'GPL',
              
                initialData:  jobData?.jobDescSkills?.jobDescription,
               
            } }
            onReady={ (editor) => {
                
                editorRef.current = editor

                
            } }
          data={jobData?.jobDescSkills?.jobDescription}
           onChange={(event: any, editor: any)=>
            
            {
              const data = editor.getData();
              handleChangeInput(data,'jobDescription')
            }
          } 
        />
            </div>
           
          </div>
          <div className='jobInputContainer'>
            <span className={clsx('jobCompanyInputLabel')}>Skills<sup>*</sup></span>
            <TextField 
            onKeyDown={(e)=>e.key==='Enter' && addSkill(newSkill)}
            onBlur={(e)=>addSkill(e?.target?.value)} 
            value={newSkill}
             classes={{root:clsx("jobCompanyInputTitle",'jobCompanyInput')}} 
             onChange={(e)=>handleChangeInput(e,'Skill')}  type='text' />
              <Stack direction="row" spacing={1} className='chipOuter'>
   {jobData?.jobDescSkills?.skills?.map((item:string,index:number)=>{
    return (      <Chip  classes={{root:'skillChipCreateJob'}}  color="primary"  label={item} variant="outlined" onDelete={(e)=>deleteSkill(index)} />
  )
   
    

   })}
     </Stack>
            </div>
            <div className='jobInputContainer'>
            <span className={clsx('jobCompanyInputLabel')}>Upload Description</span>
            {descDoc && descDoc?.length!==0?<div className='resumeButton'> 
  <Button className='resumeButtonInner' onClick={()=>deleteFile('description')} >Upload New</Button>
  </div>:<>  <label htmlFor="filePicker"   >  <div className='inputFieldLinkedinUpload' >


<img  className='iconFileUpload' src={FileUpload} /> Click to browse

</div> </label>
<input id="filePicker" onChange={(e)=>handleUploadDoc(e,'description')} accept="application/pdf" style={{display:"none"}} type={"file"} />
<span className='resumeBottomText'>Only pdf. Max size 5 MB</span>
</>}
            </div>
  
            </div>
           
        </div>)
},[jobData,newSkill,editorRef,isForEdit,documentDesc])
const renderAboutCompany=React.useMemo(()=>{
  return (<div className='jobMainWrapper'>
      <div className='educationIndex'>3</div>
       <div className='jobPartContainer'>
              <span className='jobPartContainerLabel'>About Company</span>
              <div className='inputRow'>
        <div className='jobInputContainer'>
          <span className='jobCompanyInputLabel'>Description<sup>*</sup></span>
          <CKEditor
          editor={ ClassicEditor }
          config={ {
            heading: {
              options: [
                  { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                  { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                  { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                  { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                  
                  { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                  { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' }
              ]
          },
          menuBar: {
            isVisible: true
        },
              toolbar: {
      icon: 'text',
                  items: ['heading',
          'bold',
          'italic',
          'underline',
          'paragraph',
          'essentials',
          'fontSize',
          'fontFamily',
          'fontBackgroundColor',
          'fontColor',
          'link',
          
          'outdent', 'indent',
          'bulletedList', 'numberedList' ,
          'undo','redo'
      
           ],
          },
              plugins: [
                Heading,Bold,Italic,Underline,Paragraph, Essentials,FontSize,FontFamily,
                FontBackgroundColor,FontColor,Link,Indent, IndentBlock,List
              ],
              licenseKey: 'GPL',
            
              initialData: jobData?.aboutCompany?.description,
             
          } }
          onReady={ (editor) => {
              
              editorRef.current = editor

              
          } }
         data={jobData?.aboutCompany?.description}
         onChange={(event: any, editor: any)=>
          
          {
            const data = editor.getData();
            handleChangeInput(data,'aboutDescription')
          }
        } 
      />
          </div>
         
        </div>
          </div>
      </div>)
},[jobData,editorRef])
const renderInterViewDetails=React.useMemo(()=>{
  return (<div className='jobMainWrapper'>
     
      <div className='educationIndex'>4</div>
       <div className='jobPartContainer'>
              <span className='jobPartContainerLabel'>Interview Details</span>
              <div className='inputRow'>
        <div className='jobInputContainer'>
          <span className='jobCompanyInputLabel'>Description<sup>*</sup></span>
         
          {jobData?.interviewDetails?.Joblocation?.map((item:any,index:number)=>{
            return ( <div className='inputRow'>
              <div className='jobInputContainer'>
                <span className='jobCompanyInputLabel'>Job Opening Location <sup>*</sup></span>
                <TextField  classes={{root:"jobCompanyInput"}} value={item?.location} onChange={(e)=>handleChangeInput(e,'jobOpening','location',index)}  type='text' />
                </div>
                <div className='jobInputContainer'>
                <span className={clsx('jobCompanyInputLabel')}>Number of positions to fill <sup>*</sup></span>
                <TextField value={item?.position} type="number"  classes={{root:clsx("jobCompanyInputTitle",'jobCompanyInput')}} onChange={(e)=>handleChangeInput(e,'jobOpening','position',index)}   />
                </div>
                {jobData?.interviewDetails?.Joblocation?.length>1 &&<DeleteIcon  onClick={()=>deleteContent(index,'Joblocation')} fontSize='medium'  htmlColor='grey' className='deleteIconJob'  />}

              </div>)
            
          })}
          <span onClick={()=>addMoreContent('Joblocation')} className='addMoreLocations'>+ Add More locations</span>
          <span className='noOFInterviews'>Number of interviews for this job post </span>
          {/* <div className='jobInputContainer'>
            <span className={clsx('jobCompanyInputLabel')}>Interview Name<sup>*</sup></span>
            <TextField disabled  classes={{root:clsx("jobCompanyInputInputName",'jobCompanyInput')}} value={'Self Interview'}  type='text' />
            </div>
            <div className='jobInputContainer'>
            <span className={clsx('jobCompanyInputLabel')}>Description<sup>*</sup></span>
            <textarea  rows={4}  className={clsx("jobCompanyInputInputDescription",'jobCompanyInput')}  />
            </div> */}
            
            {/* <div className='jobInputContainer'>
            <div className='jobInputContainer'>
            <span className={clsx('jobCompanyInputLabel')}>Interview Name<sup>*</sup></span>
            <TextField disabled  classes={{root:clsx("jobCompanyInputInputName",'jobCompanyInput')}} value={'Manager Interview'}  type='text' />
            </div>
            <span className={clsx('jobCompanyInputLabel')}>Description<sup>*</sup></span>
            <textarea  rows={4}  className={clsx("jobCompanyInputInputDescription",'jobCompanyInput')}  />
            </div> */}
            {jobData?.interviewDetails?.numberInterview?.map((item:any,index:number)=>{
              return (
              <div className='jobInterViewWrapper'>
             <div>
             <div className='jobInputContainer'>
            <span className={clsx('jobCompanyInputLabel')}>Interview Name<sup>*</sup></span>
            <TextField onChange={(e)=>handleChangeInput(e,'interviewDetails','interviewerName',index)} 
             classes={{root:clsx("jobCompanyInputInputName",'jobCompanyInput')}} value={item?.interviewerName}  type='text' />
            </div>
            <div className='jobInputContainer'>
                <span className={clsx('jobCompanyInputLabel')}>Description<sup>*</sup></span>
                <textarea onChange={(e)=>handleChangeInput(e,'interviewDetails','description',index)} value={item?.description}  rows={4}  className={clsx("jobCompanyInputInputDescription",'jobCompanyInput')}  />
                </div>
             </div>
                {jobData?.interviewDetails?.numberInterview?.length>1 &&<DeleteIcon  onClick={()=>deleteContent(index,'numberInterview')} fontSize='medium'  htmlColor='grey' className='deleteIconInterview'  />}

              </div>
              ) 
            })}
            <span onClick={()=>addMoreContent('numberInterview')} className='addMoreLocations'>+ Add More Interview Rounds</span>
          </div>
       
        </div>
          </div>
      </div>)
},[jobData])
const renderOthers=React.useMemo(()=>{
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  return (<div className='jobMainWrapper'>
      <div className='educationIndex'>5</div>
       <div className='jobPartContainer'>
              <span className='jobPartContainerLabel'>Other (Optional)</span> <br/>
              <span className="otherText">Use this section to add extra details for the job posting. You can make the below-added information to display as “Other Details”
              along with the rest of the job info. </span>
              <br/>
           {jobData?.others?.map((item:any,index:number)=>{
            return (<>             
             <div className='jobInfoCheckWrapper'><Checkbox
             checked={item?.postAsJobInfo}
             onChange={(e)=>handleChangeInput({target:{value:e.target.checked}},'postAsJobInfo',undefined,index)} 
             className='jobInfoCheckbox'  {...label}   />Post as Job Info              </div> 
 <div className='inputRow'>
              <div className='jobInputContainer'>
                <span className='jobCompanyInputLabel'>Parameter Name                
                </span>
                <TextField value={item?.parameterName} classes={{root:"jobCompanyInput"}}  onChange={(e)=>handleChangeInput(e,'parameterName','',index)}  type='text' />
                </div>
                <div className='jobInputContainer'>
                <span className={clsx('jobCompanyInputLabel')}>Parameter Value</span>
                <TextField value={item?.parameterValue}   classes={{root:clsx("jobCompanyInputTitle",'jobCompanyInput')}} onChange={(e)=>handleChangeInput(e,'parameterValue','',index)}  type='text' />
                </div>
                {jobData?.others?.length>1 &&<DeleteIcon  onClick={()=>deleteContent(index,'others')} fontSize='medium'  htmlColor='grey' className='deleteIconOther'  />}

              </div></>)
           })}
            <br/>
            <span onClick={()=>addMoreContent()}  className='addMoreLocations'>+ ADD MORE DETAILS</span>
   
          </div>

      </div>)
  
  },[jobData])

    return (
        <>
        <div style={{paddingRight:10}} className='titleTextPortal'>
      <div className="topButtonContainer">    
            <div><div className='posting'>Posting</div>
            <div className='add_portal'>Add from Portal</div></div>
      <Button className='signInButton' onClick={()=>nav('/job-listings')}>Cancel</Button>   
      </div>
        </div>
{renderBasicDetails}
{renderDescription}
{renderAboutCompany}
{renderInterViewDetails}
{renderOthers}
<div className='postJob'>     
  <span className='saveAsDraft'onClick={()=>createJob('Draft')} >Save as Draft</span>
       <Button className={clsx('signInButton','postButton')} onClick={()=>createJob('Active')}>{isForEdit?'SAVE':'POST'}</Button>   
</div>
<LoadingBackdrop isVisible={loading} />
<GlobalSnackbar position='bottom' message={snackbarMessage}  open={snackbarMessage!==''} />

</>
    )
}

export default CreateJob