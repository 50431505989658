import ProfileGirl from 'Assets/profileGirl.svg'
import ProfileBoy from 'Assets/profileBoy.svg'
import Contractor from 'Assets/contractor.svg'
export const CareerOptions = [{title:'Student',image:ProfileBoy,selected:false},
                       {title:'Professional',image:ProfileGirl,selected:false},
                       {title:'Contractor',image:Contractor,selected:false}]


 export const ck = ''                     

//  Hiring Manager 3
// Business Development Manager 5
// Recruiter 2
// Candidate 1
 export enum Designations {
    Hiring_Manager = 'Hiring Manager',
    Recruiter = 'Recruiter',
    BDM = 'Business Development Manager',
}