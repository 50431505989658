import React from 'react'
import { Tooltip } from '@mui/material'


interface SingleLineEllipsisProps {
  
    text: string
  limit:number
}

/**
 *
 * This component truncates long texts, and shows
 * the full text in a tooltip.
 */
const SingleLine: React.FC<SingleLineEllipsisProps> = ({
    text = '',
    limit
}) => {

   

  

    return (
        <div >
            <Tooltip title={text}>
                <div  >
                    <span >{text?.length>limit?text.substring(0,limit)+'...':text}</span>
                </div>
            </Tooltip>
           
        </div>
    )
}

export default SingleLine
