import './signup.css';
import React, { Children } from 'react';
import { Alert, Button, Grid, Snackbar, TextField, Typography } from '@mui/material';
import MicrosoftLogin from 'react-microsoft-login';
import { AuthError } from '@azure/msal-common';
import { redirect,useLocation,useNavigate } from 'react-router-dom';
import Sidebar from 'Common/Sidebar/Sidebar';
import Windows from '../../Assets/windows.png'
import TabView from 'Common/TabView/TabView';
import { validateEmail } from 'Utils/utils';
import { Global } from '@emotion/react';
import { GlobalSnackbar } from 'Common/GlobalSnackbar';
import LoadingBackdrop from 'Common/LoadingBackdrop';
import OTPVerify from 'Views/OTPVerify/OTPVerify';
import { Email } from '@mui/icons-material';
function SignUpPage() {
  const nav = useNavigate()
  const location = useLocation();

  const [activeTab,setActiveTab] = React.useState<string>('Candidate')
  const [studentName, setStudentName] = React.useState<
  string
>('')
const [token, setToken] = React.useState<
string
>('')
const [user, setUser] = React.useState<
any
>()
const [otp, changeOTP] = React.useState<
string
>('')
const [loading, setLoading] = React.useState<
boolean
>(false)
const [showOtpScreen, setShowOtpScreen] = React.useState<
boolean
>(false)
const [studentNameError, setStudentNameError] = React.useState<
boolean
>(false)
const [snackbarMessage, setSnackbarMessage] = React.useState<
string
>("")
const [studentEmail, setStudentEmail] = React.useState<
string
>('')
const [studentEmailError, setStudentEmailError] = React.useState<
boolean
>(false)
const [studentPassword, setStudentPassword] = React.useState<
string
>('')
const [studentPasswordError, setStudentPasswordError] = React.useState<
boolean
>(false)
 const loginRedirect=React.useCallback((error: AuthError | null, result?: any)=>{
console.log(error,result)
localStorage.setItem('user',JSON.stringify(result))
nav('/dashboard')
 },[])
 const handleChangeStudentInput = React.useCallback((type:string,value:string)=>{
  
  if(type==='Name'){
   if(studentNameError){
     setStudentNameError(false)
   }
   setStudentName(value)
  }
  else if(type==='Email'){
setStudentEmail(value)
if(studentEmailError){
 setStudentEmailError(false)
}
  }

  else{
   if(studentPasswordError){
     setStudentPasswordError(false)
   }
setStudentPassword(value)
  }
 },[studentNameError,studentPasswordError,studentEmailError])
 const StudentSignUp = React.useCallback(()=> {
  return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/users`, {
    method: 'POST',
    headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json'},
    body: JSON.stringify({name:studentName,email:studentEmail,password:studentPassword})
  }) .then(response => {
    return response.json();
  }).then((data:any) => {
    if(data?.message==='Email already in use!'){
      setSnackbarMessage('Email already in use , signin to activate your account')
      setTimeout(()=>{
        nav('/signin')
      },1000)
    }
    else{
      setToken(data)
      setUser(data?.newUser)
       handleSendOtp(data.token,data?.newUser?.email)
    }
    
setLoading(false)
  }).catch((error)=>{
    console.log(error,'error')
    setSnackbarMessage(error.message)
    setLoading(false)
  })
},[studentName,studentEmail,studentPassword,activeTab])
const handleClickVerification = React.useCallback(()=> {
  setLoading(true)
  return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/users/verifyOtp`, {
    method: 'POST',
    headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + token},
    body: JSON.stringify({otp:Number(otp),user:user})
  }) .then(response => {
    return response.json();
  }).then((data:any) => {
    if(data.message==='OTP verified successfully'){
      localStorage.removeItem('user')
      localStorage.setItem('user',JSON.stringify(data))
      setShowOtpScreen(false)
      setLoading(false)
      nav('/welcome',{ state: { user: data } })
    }
    else {
      setSnackbarMessage(data.message)
      setLoading(false)

    }
   

   
    
setLoading(false)
   
  }).catch((error)=>{
    console.log(error,'error')
    setSnackbarMessage(error.message)
    setLoading(false)
  })
},[otp,user])
const handleSendOtp = React.useCallback(async(userToken?:string,email?:string)=> {
  return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/users/sendOtp`, {
    method: 'POST',
    headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + (userToken??token)},
    body: JSON.stringify({token:token,email:email})

  }) .then(response => {
    return response.json();
  }).then((data:any) => {
    if(data.message==='OTP sent successfully'){
      setUser(data?.user)
      setSnackbarMessage(data.message)      
      setShowOtpScreen(true)
      setLoading(false)
    }
   
    else{
      setSnackbarMessage(data.message)
      setLoading(false)
    }
   
   
  }).catch((error)=>{
    console.log(error,'error')
    setSnackbarMessage(error.message)
    setLoading(false)
  })
},[token])
 const handleClickSignUpButton = React.useCallback(()=>{
  let inputsValidated = true
    if(studentName?.trim()?.length===0){
     setStudentNameError(true)
     inputsValidated=false
    }
   
    if(studentEmail?.trim()?.length===0 || !validateEmail(studentEmail)){
setStudentEmailError(true)
inputsValidated=false
    }
    if(studentPassword?.trim()?.length===0){
     setStudentPasswordError(true)
     inputsValidated=false
    } 
    if(inputsValidated){
      setLoading(true)
      StudentSignUp()
    }

 },[studentName,studentEmail,studentPassword])
 const setOtp=React.useCallback((otp:string)=>{
  console.log(otp)
  changeOTP(otp)

 },[])
 const handleToggleBtn=React.useCallback((value:string)=>{

  setActiveTab(value);
  
  },[])

 React.useEffect(()=>{
  if(snackbarMessage!==''){
    const messageTimeout = setTimeout(()=>{
      setSnackbarMessage('')
      clearTimeout(messageTimeout)
    },5000)
  }
 },[snackbarMessage])
 React.useEffect(()=>{
  if(location?.state?.email && location?.state?.email?.trim()?.length!==0){
    handleSendOtp(undefined,location?.state?.email)
    setUser({email:location?.state?.email})
  }
 },[location])

  return (
    <div className="loginWrapper">
      <div className='sideWrapper'><Sidebar /> </div>
      
     <div className='inputWrapper'>
     <Typography variant='h4' classes={{root:"getStartedText"}}>Get Started</Typography>
     {/* <TabView activeTab={activeTab} handleToggleBtn={handleToggleBtn} /> */}
     
      {showOtpScreen?<><OTPVerify otp={otp} setOtp={setOtp}/><Typography  onClick={()=>handleSendOtp(token,user?.email)} className='resendCodeText'>RESEND CODE</Typography></>:<>
      <TextField required error={studentNameError} value={studentName} onChange={(e)=>handleChangeStudentInput('Name',e.target.value)} classes={{root:"inputField"}} label="Name" type='text'/>
     <TextField required error={studentEmailError} value={studentEmail} onChange={(e)=>handleChangeStudentInput('Email',e.target.value)} classes={{root:"inputField"}}   label="Email" type='text' />
     <TextField required error={studentPasswordError} value={studentPassword} onChange={(e)=>handleChangeStudentInput('Password',e.target.value)} classes={{root:"inputField"}} label="Password" type='Password' /></>}
     
   <div className='buttonWrapper'>
   {showOtpScreen?<Button className='signUpButton' onClick={handleClickVerification}>Sign Up</Button> :<><Button className='signUpButton' onClick={handleClickSignUpButton}>Continue</Button>    
    {/* <MicrosoftLogin  clientId='d566b1f3-f5a0-4c9a-b056-a63c1268370c' authCallback={loginRedirect} children={<Button className='microsoftButton'> <img className='microsoftLogo' src={Windows} alt="logo" /> Microsoft Login </Button>}/> */}
     </> }  

   </div>

  </div>
<div className='signInDiv'><div className='signInText'>Have an account? </div>  <Button onClick={()=>nav('/signin')} className='signUpButton'>Sign In</Button> </div>
<GlobalSnackbar  message={snackbarMessage} open={snackbarMessage!==''} />
<LoadingBackdrop isVisible={loading} />
    </div>
  );
}

export default SignUpPage;